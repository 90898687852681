import { LimestoneExperiment, Realm } from '@amzn/limestone-experiment-portal-types';
import { getColumnDefinition } from '../experiment-table/experiment-table-definition';
import { TableProps } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { experimentDetailPage } from '../../../pages';
import { Link } from 'react-router-dom';
import { ColumnOption } from '../../../common/DisplayTable';
import { MetricData } from '../../../pages/WeeklyBusinessReviewPage/WeeklyBusinessReviewPage';

export interface BusinessReviewTableItem extends LimestoneExperiment {
    metrics?: MetricData;
}

/**
 * Column definition for WBR table in Admin portal.
 */
export function getBusinessReviewColumnDefinition(realm: Realm): Array<TableProps.ColumnDefinition<BusinessReviewTableItem>>{
    return [
        {
            id: 'id',
            header: 'ID',
            cell: (experiment) => experiment.experimentIntegerId,
            width: 150,
            sortingField: 'experimentId',
        },
        {
            id: 'title',
            header: <div style={{ 'paddingLeft': '20px' }}>Title</div>,
            cell: (experiment) => {
                return (
                    <div style={{ 'paddingLeft': '20px' }}>
                        <Link
                            to={`${experimentDetailPage.path}?experimentId=${experiment.experimentId}&experimentIntegerId=${experiment.experimentIntegerId}&realm=${realm}`}>
                            {experiment.metadata.title.displayValue}
                        </Link>
                    </div>
                );
            },
            width: 200,
            sortingField: 'title',
        },
        {
            id: 'currentStatus',
            header: 'Current Status',
            cell: (experiment) => experiment.currentStatus.currentStatus.payloadValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.currentStatus.currentStatus.payloadValue as string)
                    .localeCompare(experiment2.currentStatus.currentStatus.payloadValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'experimentId',
            header: 'ExperimentId',
            cell: (experiment) => experiment.experimentId,
            width: 350,
            sortingField: 'experimentId',
        },
        {
            id: 'startDate',
            header: 'Start Date',
            cell: (experiment) => experiment.metadata.startDate.displayValue,
            width: 150,
            sortingField: 'startDate',
        },
        {
            id: 'endDate',
            header: 'End Date',
            cell: (experiment) => experiment.metadata.endDate.displayValue,
            width: 150,
            sortingField: 'endDate',
        },
        {
            id: 'experimentType',
            header: 'Experiment Type',
            cell: (experiment) => experiment.metadata.experimentType.displayValue,
            width: 200,
            sortingField: 'experimentType',
        },
        {
            id: 'productFamily',
            header: 'Product Family',
            cell: (experiment) => experiment.metadata.productFamily.displayValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.productFamily.displayValue as string)
                    .localeCompare(experiment2.metadata.productFamily.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'primaryBusinessGroup',
            header: 'Primary Business Group',
            cell: (experiment) => experiment.metadata.primaryBusinessGroup.displayValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.primaryBusinessGroup.displayValue as string)
                    .localeCompare(experiment2.metadata.primaryBusinessGroup.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'primaryOwner',
            header: 'Primary Owner',
            cell: (experiment) => experiment.metadata.primaryOwner.displayValue,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.primaryOwner.displayValue as string)
                    .localeCompare(experiment2.metadata.primaryOwner.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'marketplace',
            header: 'Marketplace',
            cell: (experiment) => experiment.metadata.marketplace.displayValue,
            width: 150,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.marketplace.displayValue as string)
                    .localeCompare(experiment2.metadata.marketplace.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'description',
            header: 'Description',
            cell: (experiment) => experiment.metadata.description.displayValue,
            width: 300,
            sortingComparator: (experiment1, experiment2) => {
                return (experiment1.metadata.description.displayValue as string)
                    .localeCompare(experiment2.metadata.description.displayValue, undefined, { sensitivity: 'base' });
            },
        },
        {
            id: 'customerDecision',
            header: 'Customer Decision',
            cell: (experiment) => experiment.customerDecision,
            width: 300,
            sortingField: 'customerDecision',
        },
        {
            id: 'ops',
            header: 'OPS Lift',
            cell: (item) => item.metrics?.ops || '-',
            sortingField: 'opsValue'
        },
        {
            id: 'cp',
            header: 'CP Lift',
            cell: (item) => item.metrics?.cp || '-',
            sortingField: 'cpValue'
        },
        {
            id: 'gccp',
            header: 'GCCP Lift',
            cell: (item) => item.metrics?.gccp || '-',
            sortingField: 'gccpValue'
        }
    ];
}

export const wbrColumnOptions: ColumnOption[] = [
    { id: 'id', label: 'ID', editable: false, visible: true },
    { id: 'title', label: 'Title', editable: false, visible: true },
    { id: 'description', label: 'Description', editable: false, visible: true },
    { id: 'marketplace', label: 'Marketplace', editable: false, visible: true },
    { id: 'currentStatus', label: 'Current Status', editable: false, visible: true },
    { id: 'startDate', label: 'Start Date', editable: false, visible: true },
    { id: 'endDate', label: 'End Date', editable: false, visible: true },
    { id: 'experimentType', label: 'Experiment Type', editable: false, visible: false },
    { id: 'experimentId', label: 'ExperimentId', editable: false, visible: false },
    { id: 'productFamily', label: 'Product Family', editable: false, visible: true },
    { id: 'primaryBusinessGroup', label: 'Primary Business Group', editable: false , visible: false },
    { id: 'primaryOwner', label: 'Primary Owner', editable: false, visible: true },
    { id: 'customerDecision', label: 'Customer Decision', editable: false, visible: false },
    { id: 'businessRecommendation', label: 'Business Recommendation', editable: false, visible: false },
    { id: 'ops', label: 'OPS Lift', editable: false, visible: true },
    { id: 'cp', label: 'CP Lift', editable: false, visible: true },
    { id: 'gccp', label: 'GCCP Lift', editable: false, visible: true },
];
