import { Box, Button, DateRangePickerProps, Header, Select, SelectProps } from '@amzn/awsui-components-react-v3';
import { LimestoneExperiment, PageProps } from '@amzn/limestone-experiment-portal-types';
import React from 'react';
import { Component } from 'react';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import * as portalTypes from '@amzn/limestone-experiment-portal-types';
import { filterExperimentsByDate, isTestExperiment } from '../../interfaces/LimestoneExperiment';
import { DisplayTable } from '../../common/DisplayTable';
import { mapExperimentsToExperimentsTableItems } from '../../utils/experiments-table-utils';
import { pageSizeOptions } from '../../constants/table/experiment-table/experiment-table-definition';
import { getBusinessReviewColumnDefinition, wbrColumnOptions } from '../../constants/table/business-review-table/business-review-table';
import CdcApiHandlerImpl from '../../api/data-collection/handler/cdc-api-handler-impl';
import { convertMetricsTableToDataTableGroup } from '../../api/data-collection/adaptors/transactional-metrics-adaptor';
import { DataTableGroupDefinition } from '../../common/DataTableGroup';
import { EU_MARKETPLACES, FE_MARKETPLACES, NA_MARKETPLACES } from '../../constants/experiment/marketplace-list';

//Map from an experiment id to a data table group
export interface allTableGroupsDefinition {
    [key:string]: DataTableGroupDefinition[][]; 
}

export interface MetricData {
    ops?: string;
    cp?: string;
    gccp?: string;
}

export interface WeeklyBusinessReviewPageState {
    experiments: LimestoneExperiment[];
    tableLoading: boolean;
    /**
     * Selected experiment start date range to filter experiments.
     */
    selectedStartDateRangeValue: DateRangePickerProps.Value;
    yearsBack:number;
    allTableGroups: allTableGroupsDefinition;
}

class WeeklyBusinessReviewPage extends Component<PageProps, WeeklyBusinessReviewPageState> {

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI_NA: LemsApiHandler;
    public experimentServiceAPI_EU: LemsApiHandler;
    public experimentServiceAPI_FE: LemsApiHandler;
    public cdcServiceApi_NA: CdcApiHandlerImpl;
    public cdcServiceApi_EU: CdcApiHandlerImpl;
    public cdcServiceApi_JP: CdcApiHandlerImpl;

    public currentYear = new Date().getFullYear();

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tableLoading: true,
            experiments: [],
            selectedStartDateRangeValue: {
                type: 'absolute',
                startDate: new Date(this.currentYear, 0, 1).toISOString(), // January 1st
                endDate: new Date(this.currentYear, 11, 31).toISOString()  // December 31st
            },
            yearsBack:0,
            allTableGroups:{}
        };

        // Initialize LEMS API handlers for each region
        this.experimentServiceAPI_NA = new LemsApiHandlerImpl(portalTypes.Realm.NA);
        this.experimentServiceAPI_EU = new LemsApiHandlerImpl(portalTypes.Realm.EU);
        this.experimentServiceAPI_FE = new LemsApiHandlerImpl(portalTypes.Realm.FE);

        // Initialize CDC API handlers for each region
        this.cdcServiceApi_NA = new CdcApiHandlerImpl(portalTypes.Realm.NA);
        this.cdcServiceApi_EU = new CdcApiHandlerImpl(portalTypes.Realm.EU);
        this.cdcServiceApi_JP = new CdcApiHandlerImpl(portalTypes.Realm.FE);
    }
    
     componentDidUpdate = async(prevProps: PageProps) => {
         if (prevProps.realm !== this.props.realm) {
             this.experimentServiceAPI_NA = new ApiHandler(portalTypes.Realm.NA);
             this.experimentServiceAPI_EU = new ApiHandler(portalTypes.Realm.EU);
             this.experimentServiceAPI_FE = new ApiHandler(portalTypes.Realm.FE);
             await this.fetchExperiments();
         }
     }
    
        componentDidMount = async() => await this.fetchExperiments();

        downloadBusinessReviewCSV = async(businessReviews:(LimestoneExperiment & { metrics:MetricData } )[])  => {
            let csvContent = 'data:text/csv;charset=utf-8,';
            const header:string ='ID,Title,Description,Marketplace,Current Status,Start Date,End Date,Product Family,Primary Owner,OPS,CP,GCCP';
            csvContent += header+ '\r\n';
  
            businessReviews.forEach(function(experiment) {
                let row =  parseExperiment(experiment);
                csvContent += row + '\r\n';
            });
  
            var encodedUri = encodeURI(csvContent);
            window.open(encodedUri);
        }

        fetchExperiments = async() => {
            try {
                this.setState({ tableLoading: true });
                
                const [naExperiments, euExperiments, feExperiments] = await Promise.all([
                    this.experimentServiceAPI_NA.getExperimentsInGivenStatuses(experimentStatusesInWBR),
                    this.experimentServiceAPI_EU.getExperimentsInGivenStatuses(experimentStatusesInWBR),
                    this.experimentServiceAPI_FE.getExperimentsInGivenStatuses(experimentStatusesInWBR)
                ]);
        
                const allExperiments = [
                    ...naExperiments,
                    ...euExperiments,
                    ...feExperiments
                ];
        
                this.setState({ experiments: allExperiments });
                this.fetchMetrics(allExperiments);
            } catch (error) {
                handleErrorResponse(error, this.props.setNotification!, portalTypes.getExperimentsInGivenStatuses.FAIL!);
            } finally {
                this.setState({ tableLoading: false });
            }            
        }

        handleYearChange: SelectProps['onChange'] = (event) => {
            const yearsBack = parseInt(event.detail.selectedOption.value || '0');
            const selectedYear = this.currentYear - yearsBack;
            
            if (isNaN(selectedYear) || selectedYear < 0 || selectedYear > 9999) {
                console.error('Invalid year selected');
                return; 
            }
            
            this.setState({
                yearsBack,
                selectedStartDateRangeValue: {
                    type: 'absolute',
                    startDate: new Date(selectedYear, 0, 1).toISOString(),
                    endDate: new Date(selectedYear, 11, 31).toISOString()
                }
            }, this.fetchExperiments);
        }

        fetchMetrics = async(experiments: LimestoneExperiment[]) => {
            this.setState({ tableLoading: true });
            
            const NA_MARKETPLACE_VALUES = getMarketplaceValues(NA_MARKETPLACES);
            const EU_MARKETPLACE_VALUES = getMarketplaceValues(EU_MARKETPLACES);
            const FE_MARKETPLACE_VALUES = getMarketplaceValues(FE_MARKETPLACES);
            
            const newAllTableGroups: allTableGroupsDefinition = {};
            
            try {
                const results = await Promise.allSettled(
                    experiments.map(async (experiment) => {
                        let cdcApi;
        
                        if(NA_MARKETPLACE_VALUES.includes(experiment.metadata.marketplace.displayValue)){
                            cdcApi = this.cdcServiceApi_NA;
                        } else if(EU_MARKETPLACE_VALUES.includes(experiment.metadata.marketplace.displayValue)){
                            cdcApi = this.cdcServiceApi_EU;
                        } else if(FE_MARKETPLACE_VALUES.includes(experiment.metadata.marketplace.displayValue)){
                            cdcApi = this.cdcServiceApi_JP;
                        } else {
                            throw new Error(`Unknown marketplace: ${experiment.metadata.marketplace.displayValue}`);
                        }
                        
                        const metricsTables = await cdcApi.getMetricsInExperimentDashboard(experiment.experimentId);
                        const dataTableGroups = metricsTables.map((metricsTable) =>
                            convertMetricsTableToDataTableGroup(metricsTable)
                        );
                        
                        console.log('Data Table Groups Returned in Promise.allSettled for experiment '+experiment.experimentId + ', ' + JSON.stringify(dataTableGroups));
        
                        return {
                            experimentId: experiment.experimentId,
                            dataTableGroups
                        };
                    })
                );
        
                results.forEach((result) => {
                    if (result.status === 'fulfilled') {
                        newAllTableGroups[result.value.experimentId] = result.value.dataTableGroups;
                    } else {
                        console.error('Failed to fetch metrics for experiment:', result.reason);
                    }
                });
            } catch (error) {
                console.error('Error in fetchMetrics:', error);
            } finally {
                this.setState({ 
                    allTableGroups: newAllTableGroups,
                    tableLoading: false 
                });
            }
        } 

        render() {
            //Get all experiments we want to display and add the OPS/GCCP/CP metrics for each of them to the object
            const filteredExperimentsToDisplay = mapExperimentsToExperimentsTableItems(
                filterExperimentsByDate(
                    this.state.experiments, this.state.selectedStartDateRangeValue
                ).filter((experiment) => (!isTestExperiment(experiment)))
            ).map((item) => {
                console.log('Experiment id' + item.experimentId);
                console.log('All table groups:' + JSON.stringify(this.state.allTableGroups));
                console.log('Table group for experiment ' +item.experimentId +': '+ JSON.stringify(this.state.allTableGroups[item.experimentId]));
                const metrics = this.state.allTableGroups[item.experimentId] 
                    ? extractMetrics(this.state.allTableGroups[item.experimentId])
                    : {};
                return {
                    ...item,
                    metrics,
                    // Add parsed values for sorting. Remove commas so it can be parsed
                    opsValue: parseFloat(metrics.ops?.replace(/,/g, '') ?? '0'),
                    cpValue: parseFloat(metrics.cp?.replace(/,/g, '') ?? '0'),
                    gccpValue: parseFloat((metrics.gccp?.replace(/,/g, '').replace('$', '') ?? '0'))
                };
            });

            //Allow last 5 years 
            const yearOptions = Array.from({ length: 5 }, (_, i) => ({
                value: i.toString(),
                label: (this.currentYear - i).toString()
            }));
                   
            return (
                <Box margin={'xxl'}>
                    <div data-testid="WBR-Page-Box" style={{ padding: '0px' }}>
                        <h1 data-testid="WBR-Page-header">WBR</h1>
                        <p> This page is an admin only page, and a work in progress feature. Coming soon! </p>
                    </div>

                    <Box margin={{ bottom: 'l' }}>
                        <Select
                            selectedOption={{ value: (this.currentYear-this.state.yearsBack).toString() }}
                            onChange={this.handleYearChange}
                            options={yearOptions}
                            placeholder="Select a year"
                            data-testid="select-wbr-year-dropdown"
                        />
                    </Box>
                
                    <p>Finished Experiments in {this.currentYear-this.state.yearsBack}</p>
                    
                    <DisplayTable
                        title={<Box>
                            <Header variant="h2" counter={String(filteredExperimentsToDisplay.length)}>{'Business Review'}</Header>
                            <Button data-testid="WBR-Download-button" variant='primary'  disabled={(filteredExperimentsToDisplay.length === 0)} onClick={() => {
                                this.downloadBusinessReviewCSV(filteredExperimentsToDisplay);
                            }}>Download</Button>
                        </Box>}
                        items={filteredExperimentsToDisplay}
                        tableLoading={this.state.tableLoading}
                        columnDefinitions={getBusinessReviewColumnDefinition(this.props.realm)}
                        columnOptions={wbrColumnOptions}
                        pageSizeOptions={pageSizeOptions}
                        preferencesEnabled={true}
                        initialSortingDescending={true}
                        initialSortingId={'startDate'}
                    />
                </Box>
            );
        }
}

export default WeeklyBusinessReviewPage;
export const experimentStatusesInWBR=[ExperimentStatusType.COMPLETE, ExperimentStatusType.AWAITING_CUSTOMER_DECISION, ExperimentStatusType.RUNNING];

/**
 * 
 * @param experiment 
 * @returns The fields of an experiment for export, separated by commas (CSV) 
 */
export function parseExperiment(experiment: LimestoneExperiment & { metrics:MetricData } ):String {
    return [
        experiment.experimentIntegerId,
        experiment.metadata.title.displayValue,
        experiment.metadata.description.displayValue,
        experiment.metadata.marketplace.displayValue,
        experiment.currentStatus.currentStatus.payloadValue,
        experiment.metadata.startDate.displayValue,
        experiment.metadata.endDate.displayValue,
        experiment.metadata.productFamily?.displayValue || '',
        experiment.metadata.primaryOwner?.displayValue || '',
        experiment?.metrics.ops || '',
        experiment?.metrics.cp || '',
        experiment?.metrics.gccp || '',
    ].map((field) => `"${field}"`).join(',');
}

/**
 * Gets marketplace values from a marketplace options array
 * @param marketplaces Array of marketplace options
 * @returns Array of marketplace values
 */
export const getMarketplaceValues = (marketplaces: Array<SelectProps.Option>): string[] => {
    return marketplaces.map((marketplace) => marketplace.value ?? '');
};

/**
 * Extracts OPS, CP, and GCCP metrics from data table groups
 * @param dataTableGroups Array of arrays of DataTableGroupDefinition
 * @returns Formatted metric data
 */
export const extractMetrics = (dataTableGroups: DataTableGroupDefinition[][]): MetricData => {
    const metrics: MetricData = {};
    console.log('Input dataTableGroups: ' + JSON.stringify(dataTableGroups));

    if (!dataTableGroups || dataTableGroups.length === 0) {
        console.error('No Data Table Groups found');
        return metrics;
    }

    // First array contains the main metrics table groups
    const mainMetricsGroups = dataTableGroups[0];
    console.log('Main Metrics Groups: ' + JSON.stringify(mainMetricsGroups));
    if (!mainMetricsGroups || mainMetricsGroups.length === 0) {
        console.error('No Main Metrics Groups found');
        return metrics;
    }

    // Look for the "Total" table in the first group's definitions
    const totalTable = mainMetricsGroups[0]?.dataTableDefinitions
        .find((def) => def.tableName === 'Total');

    console.log('Total Table: ' + JSON.stringify(totalTable));
    
    if (totalTable) {
        totalTable.rows.forEach((row) => {
            const metricName = row[0]; // First column is the metric name
            const totalLift = row[4]; // Total lift should be in the fourth column based on the schema

            if (metricName === 'OPS') {
                console.log('OPS Found, with total lift: ' + JSON.stringify(totalLift));
                metrics.ops = totalLift;
            } else if (metricName === 'CP') {
                console.log('CP Found, with total lift: ' + JSON.stringify(totalLift));
                metrics.cp = totalLift;
            }
        });
    }

    // Second array contains the GCCP metrics table groups
    const gccpMetricsGroups = dataTableGroups[1];
    console.log('GCCP Metrics Groups: ' + JSON.stringify(gccpMetricsGroups));
    if (gccpMetricsGroups && gccpMetricsGroups.length > 0) {
        // Look for GCCP in any of the tables
        for (const group of gccpMetricsGroups) {
            for (const def of group.dataTableDefinitions) {
                const gccpRow = def.rows.find((row) => row[0] === 'GCCP');
                if (gccpRow) {
                    metrics.gccp = gccpRow[1]; // Total Lift should be in the first column
                    break;
                }
            }
            if (metrics.gccp) {
                break;
            }
        }
    } else {
        console.error('No GCCP Metric Groups found');
    }
    
    console.log('Extracted Metrics: ' + JSON.stringify(metrics));
    return metrics;
};
