import React, { Component } from 'react';
import { Box, Button, ColumnLayout, ExpandableSection, FileUpload, FileUploadProps, Spinner, TextContent } from '@amzn/awsui-components-react-v3';
import { RouteComponentProps, withRouter } from 'react-router-dom';
import { ExperimentType, IButtonHandler, LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import { EXPERIMENT_TREATMENT_COMPLETE_STATUSES, EXPERIMENT_CAN_BE_CANCELLED_STATUSES, ExperimentStatusType } from '../../enums/ExperimentStatus';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { PageProps } from '@amzn/limestone-experiment-portal-types';
import { RablRegionCreationStatusType } from '../../enums/RablRegionCreationStatus';
import { UserInputModal } from '../../common/UserInputModal';
import { EndExperimentModalAttributes, StartExperimentModalAttributes, CancelExperimentModalAttributes, Approver } from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { experimentDetailPage } from '../index';
import { ExperimentSelectionSection } from './ExperimentSelectionSection';
import { ExperimentLifecycleGraph } from './ExperimentLifecycleGraph';
import {
    ExperimentOfferDto,
    GetExperimentProgressDisplayResponse,
    OrchestrationStep,
    OrchestrationSystemOutcome
} from '@amzn/limestone-experiment-portal-types';
import { ExperimentRegionSection } from './ExperimentRegionSection';
import PageNotFound from '../ErrorPages/PageNotFound';
import { PermissionControlledButton } from '../../permissions/PermissionControlledButton';
import { addUserAccessLevelsFromExperiment } from '../../utils/auth-utils';
import { ActionType } from '@amzn/limestone-experiment-portal-types';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import * as FormUtils from '../../utils/form-utils';
import * as UrlUtils from '../../utils/url-utils';
import { ProgressStepConfiguration, ProgressStepper } from '../../common/ProgressStepper';
import { ExperimentDefinitionSection } from './ExperimentDefinitionSection';
import { ExperimentNavigationBar } from './ExperimentNavigationBar';
import { ExperimentDecisionSection } from './ExperimentDecisionSection';
import { BaseEntityType, PowerAnalysisResult } from '@amzn/limestone-experiment-portal-types';
import PowerAnalysisSection from './PowerAnalysisSection';
import AwsServicesApiHandlerImpl from '../../api/aws-services/aws-services-handler-impl';
import { AwsServicesApiHandler } from '../../api/aws-services/aws-services-handler';
import { listArtifactsFailure, deleteArtifactsFailure, uploadArtifactsFailure } from '@amzn/limestone-experiment-portal-types';

export interface ExperimentDetailPageProps extends RouteComponentProps, PageProps {}

export interface ExperimentDetailPageState {
    showSpinner: boolean;
    experimentId: string;
    experimentIntegerId: number;
    experiment: LimestoneExperiment;
    approvers: Approver[];
    offers: ExperimentOfferDto[];
    downloadButtonLoading: boolean;
    startButtonDisabled: boolean;
    startButtonLoading: boolean;
    endButtonDisabled: boolean;
    endButtonLoading: boolean;
    cancelButtonDisabled: boolean;
    cancelButtonLoading: boolean;
    showEndExperimentModal: boolean;
    showStartExperimentModal: boolean;
    showCancelExperimentModal: boolean,
    viewMetricsButtonDisabled: boolean;
    asinActionsButtonDisabled: boolean;
    currentProgressStatusRank: number;
    experimentProgressStatuses: ProgressStepConfiguration[];
    experimentDashboardButtonDisabled: boolean;
    submitForApprovalButtonLoading: boolean;
    submitForApprovalButtonDisabled: boolean;
    powerAnalysisResults: PowerAnalysisResult[];
    experimentArtifactNames:(String | undefined)[];
}

export class ExperimentDetailPage extends Component<ExperimentDetailPageProps, ExperimentDetailPageState> {
    private readonly buttonHandlers: any;
    private readonly endExperimentModalHandlers: IButtonHandler;
    private readonly startExperimentModalHandlers: IButtonHandler;
    private readonly cancelExperimentModalHandlers: IButtonHandler;

    private awsServicesHandler: AwsServicesApiHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
        this.awsServicesHandler = new AwsServicesApiHandlerImpl(props.realm);

        this.state = {
            experimentId: '',
            experimentIntegerId: -1,
            showSpinner: false,
            experiment: FormUtils.createEmptyLimestoneExperiment(),
            approvers: [],
            offers: [],
            downloadButtonLoading: false,
            endButtonDisabled: true,
            startButtonDisabled: true,
            startButtonLoading: false,
            endButtonLoading: false,
            cancelButtonDisabled: true,
            cancelButtonLoading: false,
            showEndExperimentModal: false,
            showStartExperimentModal: false,
            showCancelExperimentModal: false,
            asinActionsButtonDisabled: true,
            experimentDashboardButtonDisabled: true,
            viewMetricsButtonDisabled: true,
            currentProgressStatusRank: 0,
            experimentProgressStatuses: [],
            submitForApprovalButtonLoading: false,
            submitForApprovalButtonDisabled: true,
            powerAnalysisResults: [],
            experimentArtifactNames:[]
        };

        this.buttonHandlers = {
            start: () => this.setState({ showStartExperimentModal: true }),
            end: () => this.setState({ showEndExperimentModal: true }),
            cancel: () => this.setState({ showCancelExperimentModal: true })
        };

        this.startExperimentModalHandlers = {
            dismiss: () => this.setState({ showStartExperimentModal: false }),
            submit: () => this.startExperiment()
        };

        this.endExperimentModalHandlers = {
            dismiss: () => this.setState({ showEndExperimentModal: false }),
            submit: () => this.endExperiment()
        };

        this.cancelExperimentModalHandlers = {
            dismiss: () => this.setState({ showCancelExperimentModal: false }),
            submit: () => this.cancelExperiment()
        };
    }

    /* istanbul ignore next */
    componentDidMount = async() => {
        const { experimentId, experimentIntegerId, realm } = UrlUtils.parseQueryParametersFromUrl(new URL(window.location.href));
        if (realm && this.props.realm !== realm) {
            this.props.toggleRealm!(realm);
        
        }
        this.setState({ showSpinner: true });

        if (experimentId && experimentIntegerId) {
            this.setState({ experimentId, experimentIntegerId });

            const experiment = await this.experimentServiceAPI.readExperiment(experimentId, experimentIntegerId)
                .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.readExperiment.FAIL!));

            if (experiment) {
                this.setState({ experiment });
                this.setButtonsDisabledState(experiment);

                await this.experimentServiceAPI.getExperimentProgressDisplay(experimentId)
                    .then((response: GetExperimentProgressDisplayResponse) => {
                        this.setState({
                            currentProgressStatusRank: response.currentStatusRank,
                            experimentProgressStatuses: response.progressStatusList.map((status) => {
                                return {
                                    label: status.displayName,
                                    executionTimeInMins: status.executionTimeInMins
                                };
                            })
                        });
                    })
                    .catch((error: any) => {
                        if (this.props.userAttributes?.isAdmin) {
                            handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentProgressDisplay.FAIL!);
                        }
                    });

                await this.experimentServiceAPI.getAllExperimentApprovers(experimentId)
                    .then((response: Approver[]) => this.setState({ approvers: response }))
                    .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getAllExperimentBoundaries.FAIL!))
                    .finally(() => this.setState({ showSpinner: false }));

                if (experiment.currentStatus.currentStatus.payloadValue === ExperimentStatusType.SUBMIT_FOR_APPROVAL) {
                    this.props.setNotification!(NOTIFICATION_MESSAGES.submitForApproval, (
                        <div>After reviewing pre-experiment analysis results, submit the experiment for Business and
                            ReSES Admin approvals.
                        </div>
                    ));
                }
                this.listExperimentArtifacts();
                this.setState({ powerAnalysisResults: await this.experimentServiceAPI.getPowerAnalysisResults(experimentId) });
            }
        }
    }

    setButtonsDisabledState = (experiment: LimestoneExperiment) => {
        const experimentStatus: ExperimentStatusType = experiment.currentStatus.currentStatus.payloadValue;
        if (experimentStatus === ExperimentStatusType.APPROVED
          && (experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.LIVE || experiment.metadata.experimentType.payloadValue === ExperimentType.RESULT_ANALYSIS_ONLY)) {
            this.setState({ startButtonDisabled: false });
        }

        if (experimentStatus === ExperimentStatusType.RUNNING) {
            this.setState({ endButtonDisabled: false, asinActionsButtonDisabled: false, viewMetricsButtonDisabled: false });
        }

        if (EXPERIMENT_TREATMENT_COMPLETE_STATUSES.includes(experimentStatus)) {
            this.setState({ experimentDashboardButtonDisabled: false });
        }

        if (experimentStatus === ExperimentStatusType.SUBMIT_FOR_APPROVAL) {
            this.setState( { submitForApprovalButtonDisabled: false });
        }

        if (EXPERIMENT_CAN_BE_CANCELLED_STATUSES.includes(experimentStatus)) {
            this.setState({ cancelButtonDisabled: false });
        }
    }

    startExperiment = async() => {
        this.setState({ startButtonLoading: true, showStartExperimentModal: false });

        await this.experimentServiceAPI.startExperiment(this.state.experimentId, this.state.experiment.metadata.experimentType.payloadValue)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.startExperiment.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.startExperiment.FAIL!))
            .finally(() => this.setState({ startButtonLoading: false, startButtonDisabled: true }));
    }

    endExperiment = async() => {
        this.setState({ endButtonLoading: true, showEndExperimentModal: false });

        await this.experimentServiceAPI.endExperiment(this.state.experimentId)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.endExperiment.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.endExperiment.FAIL!))
            .finally(() => this.setState({ endButtonLoading: false, endButtonDisabled: true }));
    }

    cancelExperiment = async() => {
        this.setState({ cancelButtonLoading: true, showCancelExperimentModal: false });
        await this.experimentServiceAPI.cancelExperiment(this.state.experimentId, this.props.userAttributes?.username!)
            .then(() => this.props.setNotification!(NOTIFICATION_MESSAGES.cancelExperiment.SUCCESS))
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.cancelExperiment.FAIL!))
            .finally(() => this.setState({ cancelButtonLoading: false, cancelButtonDisabled: true }));
    }

    submitForApproval = async() => {
        this.setState({ submitForApprovalButtonLoading: true });

        try {
            await this.experimentServiceAPI.sendOrchestratorCompletionEvent({
                baseEntityType: BaseEntityType.EXPERIMENT,
                baseEntityId: this.state.experimentId,
                stepName: OrchestrationStep.SUBMIT_FOR_APPROVAL,
                systemOutput: '',
                systemOutcome: OrchestrationSystemOutcome.PASS
            });
            this.props.setNotification!(NOTIFICATION_MESSAGES.submitForApprovalApiResponse.SUCCESS);
        } catch (error) {
            handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitForApprovalApiResponse.FAIL!);
        }
        this.setState({ submitForApprovalButtonLoading: false, submitForApprovalButtonDisabled: true });
    }

    listExperimentArtifacts = async() => {
        let arr = await this.awsServicesHandler.listExperimentArtifacts(this.state.experimentId)
            .catch((error: any) => {
                handleErrorResponse(error, this.props.setNotification!, listArtifactsFailure);
            });
        if(arr){
            this.setState( { experimentArtifactNames:arr.map((artifact) => { return artifact.Key; }) });
        }
    }

    deleteExperimentArtifact = async(artifactName:string) => {
        let newArtifactsList = await this.awsServicesHandler.deleteExperimentArtifact(this.state.experimentId,artifactName)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, deleteArtifactsFailure));;
        if(newArtifactsList){
            this.setState( { experimentArtifactNames:newArtifactsList.map((artifact) => { return artifact.Key; }) });
        }
    }

    uploadExperimentArtifacts = async(detail:FileUploadProps.ChangeDetail) => {
        let uploaded = await this.awsServicesHandler.uploadExperimentArtifacts(this.state.experimentId, detail.value)
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, uploadArtifactsFailure));
        if(uploaded){
            this.setState( { experimentArtifactNames:uploaded.map((artifact) => { return artifact.Key; }) });
        }
    }

    downloadExperimentArtifact = async(artifactName:string) => {
        let presignedURL= await this.awsServicesHandler.downloadExperimentArtifact(this.state.experimentId,artifactName);
        window.open(presignedURL, '_blank');
    }

    render() {
        const userAccessLevels = addUserAccessLevelsFromExperiment(this.state.experiment.metadata, this.props.userAttributes?.username!, this.props.userAccessLevels);
        const additionalExperimentNavigationButtons = [
            <PermissionControlledButton
                userAccessLevels={this.props.userAccessLevels}
                actionType={ActionType.ADMIN_ACTION}
                pagePermissionsMap={this.props.permissionsMap}
                testId={'start-experiment-button'}
                hideIfNotAuthorized={true}
                buttonProps={{
                    loading: this.state.startButtonLoading,
                    disabled: this.state.startButtonDisabled,
                    onClick: this.buttonHandlers.start
                }}
                key='start-experiment-button'
            >Start Experiment</PermissionControlledButton>,

            <PermissionControlledButton
                userAccessLevels={this.props.userAccessLevels}
                actionType={ActionType.ADMIN_ACTION}
                pagePermissionsMap={this.props.permissionsMap}
                testId={'end-experiment-button'}
                hideIfNotAuthorized={true}
                buttonProps={{
                    loading: this.state.endButtonLoading,
                    disabled: this.state.endButtonDisabled,
                    onClick: this.buttonHandlers.end
                }}
                key='end-experiment-button'
            >End Experiment</PermissionControlledButton>,

            <PermissionControlledButton
                userAccessLevels={this.props.userAccessLevels}
                actionType={ActionType.ADMIN_ACTION}
                pagePermissionsMap={this.props.permissionsMap}
                testId={'cancel-experiment-button'}
                hideIfNotAuthorized={true}
                buttonProps={{
                    loading: this.state.cancelButtonLoading,
                    disabled: this.state.cancelButtonDisabled,
                    onClick: this.buttonHandlers.cancel
                }}
                key='cancel-experiment-button'
            >Cancel Experiment</PermissionControlledButton>
        ];

        const actionStripe = <ExperimentNavigationBar
            experiment={this.state.experiment}
            additionalButtons={additionalExperimentNavigationButtons}
            realm={this.props.realm}
            userAccessLevels={this.props.userAccessLevels}
            currentPage={experimentDetailPage}
        />;

        const modals = (
            <>
                <UserInputModal
                    visible={this.state.showStartExperimentModal}
                    buttonHandlers={this.startExperimentModalHandlers}
                    {...StartExperimentModalAttributes}
                />

                <UserInputModal
                    visible={this.state.showStartExperimentModal}
                    buttonHandlers={this.startExperimentModalHandlers}
                    {...StartExperimentModalAttributes}
                />

                <UserInputModal
                    visible={this.state.showEndExperimentModal}
                    buttonHandlers={this.endExperimentModalHandlers}
                    {...EndExperimentModalAttributes}
                />

                <UserInputModal
                    visible={this.state.showCancelExperimentModal}
                    buttonHandlers={this.cancelExperimentModalHandlers}
                    {...CancelExperimentModalAttributes}
                />
            </>
        );

        let content: JSX.Element;
        if (this.state.showSpinner) {
            content = (<Spinner size='large' />);
        } else if (this.state.experimentId === '') {
            content = (<PageNotFound />);
        } else {
            content = (<>
                {actionStripe}

                <ProgressStepper
                    testId='experiment-progress-display'
                    steps={this.state.experimentProgressStatuses}
                    activeStepIndex={this.state.currentProgressStatusRank}
                />

                <ExperimentDefinitionSection
                    testId={'experiment-definition-section'}
                    realm={this.props.realm}
                    experimentId={this.state.experimentId}
                    metadata={this.state.experiment.metadata}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                    experimentStatus={this.state.experiment.currentStatus.currentStatus.payloadValue}
                />

                <ExperimentSelectionSection
                    testId={'experiment-selection-section'}
                    realm={this.props.realm}
                    experimentLifecycleType={this.state.experiment.metadata.experimentType.payloadValue}
                    experimentId={this.state.experimentId}
                    marketplaceId={this.state.experiment.metadata.marketplace.payloadValue}
                    experimentStatus={this.state.experiment.currentStatus.currentStatus.payloadValue}
                    setNotification={this.props.setNotification!}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                    selectionFinalized={this.state.experiment.selectionFinalized}
                />

                <ExperimentRegionSection
                    testId={'experiment-region-section'}
                    realm={this.props.realm}
                    experiment={this.state.experiment}
                    experimentId={this.state.experimentId}
                    setNotification={this.props.setNotification!}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                />

                {this.state.powerAnalysisResults.length > 0 && <PowerAnalysisSection
                    data-testid={'power-analysis-section'}
                    powerAnalysisResults={this.state.powerAnalysisResults}
                />}

                {EXPERIMENT_TREATMENT_COMPLETE_STATUSES.map((status) => status.toString()).includes(this.state.experiment.currentStatus.currentStatus.payloadValue)
                    && <ExperimentDecisionSection
                        testId={'experiment-decision-section'}
                        realm={this.props.realm}
                        experiment={this.state.experiment}
                        experimentId={this.state.experimentId}
                        setNotification={this.props.setNotification!}
                        userAccessLevels={this.props.userAccessLevels}
                        pagePermissionsMap={this.props.permissionsMap}
                        isAdmin={this.props.userAttributes!.isAdmin}
                    />
                }
                <div style={{ paddingBottom: 20 }}>
                    <ExpandableSection headerText={'Experiment Artifacts'} variant='container'>
                        <FileUpload
                            onChange={(e)=>{
                                this.uploadExperimentArtifacts(e.detail);
                            }}
                            data-testid='experiment-artifacts-section-item-upload-button'
                            value={[]}
                            i18nStrings={{
                                uploadButtonText: (e) =>
                                    e ? 'Choose files' : 'Choose file',
                                dropzoneText: (e) =>
                                    e
                                        ? 'Drop files to upload'
                                        : 'Drop file to upload',
                                removeFileAriaLabel: (e) =>
                                    `Remove file ${e + 1}`,
                                limitShowFewer: 'Show fewer files',
                                limitShowMore: 'Show more files',
                                errorIconAriaLabel: 'Error'
                            }}
                            showFileLastModified
                            showFileSize
                            showFileThumbnail
                            tokenLimit={3}
                            constraintText='Upload Experiment Approvals, Launch Decisions, or any other files related to this experiment.'
                        />
                        <TextContent data-testid="experiment-artifacts-section">
                            <h1 key={'header'}> Artifacts </h1>
                            <ul>
                                {this.state.experimentArtifactNames.map((artifact)=>{
                                    if(artifact && !(artifact.lastIndexOf('/')===artifact.length-1)) {
                                        // replace the Folder Path of "[experimentID]/test.png" and return "test.png"
                                        let artifactName = artifact.replace(this.state.experimentId+'/', '');

                                        return (
                                            <div key={artifactName +'div'} style={ { display:'grid',  gridTemplateColumns:'1fr 0.1fr 0.1fr' } }>
                                                <li data-testid='experiment-artifacts-section-items' key={artifactName + 'item'} >{artifactName}</li>
                                                <Button
                                                    iconName='download'
                                                    variant='link'
                                                    key={artifactName + 'download'}
                                                    fullWidth={false}
                                                    data-testid='experiment-artifacts-section-item-download-button'
                                                    onClick={() => this.downloadExperimentArtifact(artifactName)}
                                                >
                                                </Button>
                                                <Button
                                                    iconName='delete-marker'
                                                    variant='link'
                                                    key={artifactName + 'upload'}
                                                    fullWidth={false}
                                                    data-testid='experiment-artifacts-section-item-delete-button'
                                                    onClick={() => this.deleteExperimentArtifact(artifactName)}
                                                >
                                                </Button>
                                            </div>
                                        );
                                    }
                                    else {
                                        return null;
                                    }
                                })}
                            </ul>
                        </TextContent>
                        {this.state.experimentArtifactNames.length === 0 && <p>No Artifacts found</p>}
                    </ExpandableSection>
                </div>

                <div style={{ paddingBottom: 20 }}>
                    <ExpandableSection
                        variant='container'
                        data-testid={'experiment-approval-status-section'}
                        headerText='Experiment Approvals'
                        headerActions={
                            <PermissionControlledButton
                                userAccessLevels={this.props.userAccessLevels}
                                pagePermissionsMap={this.props.permissionsMap}
                                actionType={ActionType.WRITE}
                                buttonProps={{
                                    loading: this.state.submitForApprovalButtonLoading,
                                    disabled: this.state.submitForApprovalButtonDisabled,
                                    onClick: async(event) => {
                                        event.stopPropagation();
                                        await this.submitForApproval();
                                    },
                                }}
                                testId='submitForApprovalButton'
                            >Submit For Approval</PermissionControlledButton>
                        }>
                        <ColumnLayout columns={4} variant='text-grid'>
                            <Box variant='awsui-key-label'><Box variant='strong'><u>{'Approver Alias'}</u></Box></Box>
                            <Box variant='awsui-key-label'><Box variant='strong'><u>{'Approver Role'}</u></Box></Box>
                            <div><Box variant='strong'><u>{'Approval Status'}</u></Box></div>
                            <div><Box variant='strong'><u>{'Reason'}</u></Box></div>
                        </ColumnLayout>
                        {this.state.approvers.map((approver: Approver) => {
                            return (
                                <div key={approver.alias}>
                                    <ColumnLayout columns={4} variant='text-grid'>
                                        <Box variant='awsui-key-label'><Box variant='strong'>{approver.alias}</Box></Box>
                                        <Box variant='awsui-key-label'>{approver.role}</Box>
                                        <div>{approver.status}</div>
                                        <div>{approver.rejectionReason}</div>
                                    </ColumnLayout>
                                </div>
                            );
                        })}
                    </ExpandableSection>
                </div>

                <PermissionControlledView
                    hideIfNotAuthorized={true}
                    actionType={ActionType.ADMIN_ACTION}
                    userAccessLevels={this.props.userAccessLevels}
                    pagePermissionsMap={this.props.permissionsMap}
                >
                    <div style={{ paddingBottom: 20 }}>
                        <ExpandableSection
                            variant='container'
                            headerText={'Lifecycle Graph'}>
                            <ExperimentLifecycleGraph
                                testId={'lifecycle-graph'}
                                realm={this.props.realm}
                                experimentId={this.state.experimentId}
                                setNotification={this.props.setNotification!}
                            />
                        </ExpandableSection>
                    </div>
                </PermissionControlledView>

                {modals}
            </>);
        }

        return (
            <PermissionControlledView
                userAccessLevels={userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <div style={{ padding: 5 }}>
                    {content}
                </div>
            </PermissionControlledView>
        );
    }
}

export default withRouter(ExperimentDetailPage);
