
import React from 'react';
import { RadioGroupField, RadioGroupFieldConfig } from '../fields/RadioGroupField';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { DisplayMode, AttributeLabels, Realm } from '@amzn/limestone-experiment-portal-types';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { LabelWithTooltip } from './LabelWithTooltip';
import { Box } from '@amzn/awsui-components-react-v3';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export class LaunchDecision extends RadioGroupField {
    protected displayConfig: RadioGroupFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;
        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.displayConfig = {
            label: AttributeLabels.CUSTOMER_DECISION,
            editable: true,
            touched: false,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, ''),
            value: initialValue ? initialValue : 'INCONCLUSIVE',
            items: [
                { value: 'LAUNCHED', label: 'Conclusive Launch' },
                { value: 'NOT_LAUNCHED', label: 'Conclusive No Launch' },
                { value: 'INCONCLUSIVE', label: 'Inconclusive' }
            ]
        };

        this.state = {
            displayValue: initialValue,
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: true,
            editInProgress: false
        };
    }

    componentDidMount = async() => {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue);
        }
    }

    editSubmitButtonClicked = async(): Promise<boolean> => {
        this.setState({ editInProgress: true });
        const response = await this.experimentServiceAPI.updateCustomerDecision(this.props.experimentId!, this.getPayloadValue())
            .finally(() => { this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW }); });

        return response;
    }

    renderViewMode = () => (
        <div>
            <div style={{ display: 'flex' }}>
                <LabelWithTooltip label={<div data-testid={`display-wrapper-${this.displayConfig.label}`} style={{ display: 'table-cell' }}>
                    <Box variant="awsui-key-label"><Box variant="strong"><u>{this.displayConfig.label}</u></Box></Box>
                </div>} toolTipText={this.displayConfig.toolTipText}/>
                {this.displayConfig.editable && this.props.isAuthorizedToEdit ? (<IconButton style={{ height: 0 }} onClick={() => this.setState({ displayMode: DisplayMode.EDIT })}><EditIcon fontSize='small'/></IconButton>) : null}
            </div>
            <div>{this.state.displayValue}</div>
        </div>
    );
}