import React, { Component, ReactNode } from 'react';
import {
    Alert,
    Box,
    Button,
    ColumnLayout,
    ExpandableSection,
    Header,
} from '@amzn/awsui-components-react-v3';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import LemsApiHandlerImpl from '../../api/experiment-service/handler/lems-api-handler-impl';
import { UserInputModal } from '../../common/UserInputModal';
import {
    SubmitBoundariesModalAttributes,
    SubmitOverrideBoundariesModalAttributes,
    IButtonHandler, RegionSelectionAttribute, ExperimentTreatmentType
} from '@amzn/limestone-experiment-portal-types';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import { DisplayTable } from '../../common/DisplayTable';
import { getColumnDefinitions, getColumnOptions, pageSizeOptions } from '../../constants/table/boundary-set-attributes';
import { LifecycleType, Realm } from '@amzn/limestone-experiment-portal-types';
import { RablRegionCreationStatusType } from '../../enums/RablRegionCreationStatus';
import { ActionRequiredMessages, AttributeLabels, BoundarySet, TableHeaders } from '@amzn/limestone-experiment-portal-types';
import { Boundaries } from '../../form/attributes/Boundaries';
import { ActionType, Boundary, PermissionsMap, UserAccessLevel } from '@amzn/limestone-experiment-portal-types';
import { PermissionControlledButton } from '../../permissions/PermissionControlledButton';
import { DisplayAttribute, getActionRequiredNotification, IProps, LimestoneExperiment, LimestoneExperimentBoundaries } from '@amzn/limestone-experiment-portal-types';
import { EMBED_BOUNDARIES_MAP, EMBED_REGION_MAP, EXCLUDES, INCLUDES, RABL_BASE_URLS } from '@amzn/limestone-experiment-portal-types';
import RMSApiHandler from '../../api/region-service/handler/rms-api-handler';
import { removeLemsBoundaryPrefix } from '../../api/experiment-service/adaptors/region-selection-adaptor';
import { TreatmentRegionUploadTypeField } from '../../form/attributes/TreatmentRegionUploadType';
import { RegionFile } from '../../form/attributes/RegionFile';
import { TreatmentRegionSelectedUploadType } from '../../enums/TreatmentRegionSelectedUploadType';
import { ExperimentRegionType } from '../../enums/ExperimentRegionType';

export interface ExperimentRegionSectionProps extends IProps {
    realm: Realm;
    experimentId: string;
    setNotification: Function;
    userAccessLevels: Set<UserAccessLevel>;
    pagePermissionsMap: PermissionsMap;
    experiment: LimestoneExperiment;
}

export interface ExperimentRegionSectionState {
    isLoading: boolean;
    regionSimulationBoundarySets: BoundarySet[];
    manualOverrideBoundarySets: BoundarySet[];
    selectedBoundarySet: BoundarySet|null;
    displayRegionResults: boolean;
    submitBoundariesButtonDisabled: boolean;
    submitBoundariesButtonLoading: boolean;
    showSubmitBoundariesModal: boolean;
    manuallyUploadSelectedBoundaries: string[];
    submitOverrideBoundariesButtonDisabled: boolean;
    submitOverrideBoundariesButtonLoading: boolean;
    showSubmitOverrideBoundariesModal: boolean;
    experimentTreatmentRegionSelection: LimestoneExperimentBoundaries;
    experimentControlRegionSelection: LimestoneExperimentBoundaries;
    rablEmbedUrl: string;
    useTreatmentRegionFile?: string;
}

export class ExperimentRegionSection extends Component<ExperimentRegionSectionProps, ExperimentRegionSectionState> {
    private readonly buttonHandlers: any;
    private readonly submitBoundariesModalHandlers: IButtonHandler;
    private readonly submitOverrideBoundariesModalHandler: IButtonHandler;

    /** Experiment Service handler instance which provides api to get the experiment data from the backend */
    public experimentServiceAPI: LemsApiHandler;

    public regionManagementServiceAPI: RMSApiHandler;

    public constructor(props: any) {
        super(props);

        this.experimentServiceAPI = new LemsApiHandlerImpl(props.realm);
        this.regionManagementServiceAPI = new RMSApiHandler(props.realm);

        this.state = {
            isLoading: false,
            regionSimulationBoundarySets: [],
            manualOverrideBoundarySets: [],
            selectedBoundarySet: null,
            displayRegionResults: false,
            submitBoundariesButtonDisabled: true,
            submitBoundariesButtonLoading: false,
            showSubmitBoundariesModal: false,
            manuallyUploadSelectedBoundaries: [],
            submitOverrideBoundariesButtonDisabled: true,
            submitOverrideBoundariesButtonLoading: false,
            showSubmitOverrideBoundariesModal: false,
            experimentTreatmentRegionSelection: {
                boundaries: new DisplayAttribute(AttributeLabels.TREATMENT_BOUNDARIES)
            },
            experimentControlRegionSelection: {
                boundaries: new DisplayAttribute(AttributeLabels.CONTROL_BOUNDARIES)
            },
            rablEmbedUrl: '',
        };

        this.buttonHandlers = {
            submitBoundaries: () => this.setState({ showSubmitBoundariesModal: true }),
            submitOverrideBoundaries: () => this.setState({ showSubmitOverrideBoundariesModal: true })
        };

        this.submitBoundariesModalHandlers = {
            dismiss: () => this.setState({ showSubmitBoundariesModal: false }),
            submit: () => this.submitFinalBoundaries()
        };

        this.submitOverrideBoundariesModalHandler = {
            dismiss: () => this.setState({ showSubmitOverrideBoundariesModal: false }),
            submit: () => this.submitOverrideBoundaries()
        };
    }

    componentDidMount = async() => {
        if (this.props.experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.AWAITING_USER_RESPONSE) {
            this.setState({ isLoading: true });
            try {
                const boundarySets = await this.experimentServiceAPI.getExperimentBoundaryOptions(this.props.experiment.experimentId);
                for (const boundarySet of boundarySets) {
                    boundarySet.rmsBoundaries = await this.getRmsBoundariesForBoundarySet(boundarySet);
                }
                this.setState({
                    regionSimulationBoundarySets: boundarySets.filter((boundarySet) => !boundarySet.isManuallyUploaded),
                    manualOverrideBoundarySets: boundarySets.filter((boundarySets) => boundarySets.isManuallyUploaded),
                    displayRegionResults: true,
                });

                this.props.setNotification!(getActionRequiredNotification('CONFIRM_TREATMENT_REGION', ActionRequiredMessages.CONFIRM_TREATMENT_REGION));
            } catch (error) {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!);
            } finally {
                this.setState({ isLoading: false });
            }
        } else {
            try {
                const response = await this.experimentServiceAPI.getAllExperimentBoundaries(this.props.experiment.experimentId, ExperimentRegionType.TREATMENT);
                this.setState({ experimentTreatmentRegionSelection: response! });
                const controlBoundaries = await this.experimentServiceAPI.getAllExperimentBoundaries(this.props.experiment.experimentId, ExperimentRegionType.CONTROL);
                this.setState({ experimentControlRegionSelection: controlBoundaries! });

            } catch (error) {
                handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentBoundaries.FAIL!);
            }
        }
        this.setState({
            rablEmbedUrl: await this.getRablEmbedUrl()
        });
    }

    submitFinalBoundaries = async() => {
        this.setState({ submitBoundariesButtonLoading: true, showSubmitBoundariesModal: false });

        await this.experimentServiceAPI.finalizeExperimentBoundaries(this.props.experimentId, this.state.selectedBoundarySet!)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitExperimentBoundaries.SUCCESS);
                window.location.reload();
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitExperimentBoundaries.FAIL!))
            .finally(() => this.setState({ submitBoundariesButtonLoading: false }));
    }

    submitOverrideBoundaries = async() => {
        this.setState({ submitOverrideBoundariesButtonLoading: true, showSubmitOverrideBoundariesModal: false });
        await this.experimentServiceAPI.manualOverrideBoundaries(this.props.experimentId, this.state.manuallyUploadSelectedBoundaries)
            .then(() => {
                this.props.setNotification!(NOTIFICATION_MESSAGES.submitExperimentBoundaries.SUCCESS);
                window.location.reload();
            })
            .catch((error: any) => handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.submitExperimentBoundaries.FAIL!))
            .finally(() => this.setState({ submitOverrideBoundariesButtonLoading: false }));
    }

    onBoundarySelectionChange = (selectedBoundarySets: BoundarySet[]) => {
        if (selectedBoundarySets.length === 0) {
            this.setState({ selectedBoundarySet: null, submitBoundariesButtonDisabled: true });
        } else if (selectedBoundarySets.length === 1) {
            this.setState({ selectedBoundarySet: selectedBoundarySets[0], submitBoundariesButtonDisabled: false });
        } else {
            // multiple boundary sets are selected, which is not allowed.
            console.warn('Multiple boundary sets are selected.');
            this.setState({ selectedBoundarySet: null, submitBoundariesButtonDisabled: true });
        }
    }

    getBoundarySetsTableColumnName = (array: BoundarySet[]) => {
        return array.length ? Array.from(array[0].attributes.keys()) : [];
    }

    updateFormState = (_fieldId: string, payloadValue: any, _displayValue: string, _isValid: boolean): void => {
        this.setState({
            manuallyUploadSelectedBoundaries: payloadValue,
            submitOverrideBoundariesButtonDisabled: !_isValid || payloadValue.length === 0
        });
    }

    getRmsBoundariesForBoundarySet = async(boundarySet: BoundarySet): Promise<Map<string, Boundary>> => {
        const boundaries = new Map<string, Boundary>();

        const boundaryOutputs = await this.regionManagementServiceAPI.getBoundariesInBatch(
            this.props.experiment.metadata.marketplace.displayValue,
            this.props.experiment.metadata.regionDefinitionType.payloadValue,
            Array.from(boundarySet.boundaries.keys()));

        boundaryOutputs.forEach((boundaryOutput) => {
            boundaries.set(boundaryOutput.boundaryName, boundaryOutput);
        });

        return boundaries;
    }

    getRablEmbedUrl = async() => {
        if (this.props.experiment.metadata.experimentType.payloadValue === LifecycleType.RESULT_ANALYSIS_ONLY) {
            const boundaryNames = this.state.experimentTreatmentRegionSelection!.boundaries.payloadValue ? 
                this.state.experimentTreatmentRegionSelection!.boundaries.payloadValue
                    .map((boundaryName: string) => removeLemsBoundaryPrefix(boundaryName)): [];
            const boundaryOutputs = await this.regionManagementServiceAPI.getBoundariesInBatch(
                this.props.experiment.metadata.marketplace.displayValue,
                this.props.experiment.metadata.regionDefinitionType.payloadValue,
                boundaryNames);
            const boundaryIds = boundaryOutputs.map((boundaryOutput) => boundaryOutput.rablId);
            return `${RABL_BASE_URLS.getEndpoint(Realm.NA) + EMBED_BOUNDARIES_MAP}?${INCLUDES}=${[]}&${EXCLUDES}=${boundaryIds}`;
        } else {
            return RABL_BASE_URLS.getEndpoint(this.props.realm) + EMBED_REGION_MAP + this.props.experiment.metadata.rablRegionId.payloadValue;
        }
    }

  getSelectionRegionStep = (): ReactNode => {
      let regionSelectionOption = null;
      if (this.state.useTreatmentRegionFile === TreatmentRegionSelectedUploadType.DROPDOWN) {
          regionSelectionOption = <Boundaries
              data-testid='boundaries-dropdown'
              updateFormState={this.updateFormState}
              marketplaceId={this.props.experiment.metadata.marketplace.payloadValue}
              experimentId={this.props.experiment.experimentId}
              realm={this.props.realm}
              definitionType={this.props.experiment.metadata.regionDefinitionType.payloadValue}
              startDate={this.props.experiment.metadata.startDate.payloadValue}
              endDate={this.props.experiment.metadata.endDate.payloadValue}
              initialValue={this.props.experiment.treatmentRegionSelection.boundaries.payloadValue}
              key={this.props.experiment.metadata.regionDefinitionType.payloadValue}
              allowOverlappingBoundaries={this.props.experiment.metadata.treatmentType.payloadValue !== ExperimentTreatmentType.OFFER_RESTRICTION.toString()}
              regionSelectionAttribute={RegionSelectionAttribute.TREATMENT_BOUNDARIES}
          />;
      } else if (this.state.useTreatmentRegionFile === TreatmentRegionSelectedUploadType.UPLOAD_FILE) {
          regionSelectionOption = <RegionFile
              data-testid='boundaries-file-upload'
              updateFormState={this.updateFormState}
              marketplaceId={this.props.experiment.metadata.marketplace.payloadValue}
              experimentId={this.props.experiment.experimentId}
              realm={this.props.realm}
              definitionType={this.props.experiment.metadata.regionDefinitionType.payloadValue}
              startDate={this.props.experiment.metadata.startDate.payloadValue}
              endDate={this.props.experiment.metadata.endDate.payloadValue}
              initialValue={this.props.experiment.treatmentRegionSelection.boundaries.payloadValue}
              key={this.props.experiment.metadata.regionDefinitionType.payloadValue}
              allowOverlappingBoundaries={this.props.experiment.metadata.treatmentType.payloadValue !== ExperimentTreatmentType.OFFER_RESTRICTION.toString()}
              initialFileName={this.props.experiment.treatmentRegionSelection.boundaries.displayValue}
              otherBoundary={undefined}
              regionSelectionAttribute={RegionSelectionAttribute.TREATMENT_BOUNDARIES}
          />;
      }
      return regionSelectionOption;
  }
  render() {
      const modals = (
          <>
              <UserInputModal
                  visible={this.state.showSubmitBoundariesModal}
                  buttonHandlers={this.submitBoundariesModalHandlers}
                  {...SubmitBoundariesModalAttributes}
              />
              <UserInputModal
                  visible={this.state.showSubmitOverrideBoundariesModal}
                  buttonHandlers={this.submitOverrideBoundariesModalHandler}
                  {...SubmitOverrideBoundariesModalAttributes}
              />
          </>
      );

      const treatmentBoundariesList = <div style={{ display: 'table' }}>
          <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
              <Box variant="awsui-key-label">
                  <Box variant="strong"><u>{this.state.experimentTreatmentRegionSelection!.boundaries.displayLabel}</u></Box>
              </Box>
              <div style={{ maxHeight: 120, overflow: 'auto' }}>{this.state.experimentTreatmentRegionSelection!.boundaries.displayValue}</div>
          </div>
      </div>;

      const controlBoundariesList = <div style={{ display: 'table' }}>
          <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
              <Box variant="awsui-key-label">
                  <Box variant="strong"><u>{'Control Boundaries'}</u></Box>
              </Box>
              <div style={{ maxHeight: 120, overflow: 'auto' }}>{
                this.state.experimentControlRegionSelection!.boundaries.displayValue === '' ?
                    'Default control region (rest of marketplace)' :
                  this.state.experimentControlRegionSelection!.boundaries.displayValue}</div>
          </div>
      </div>;

      const regionStatus = <div style={{ display: 'table' }}>
          <div data-testid={'display-wrapper'} style={{ display: 'table-cell' }}>
              <Box variant="awsui-key-label">
                  <Box variant="strong"><u>{this.props.experiment.metadata.regionCreationStatus.displayLabel}</u></Box>
              </Box>
              <div>{this.props.experiment.metadata.regionCreationStatus.displayValue}</div>
          </div>
      </div>;
  
      const content = (<>
          <ExpandableSection
              variant='container'
              headerText={'Treatment/Control Regions'}
              headerActions={
                  this.props.experiment.metadata.experimentType.payloadValue !== LifecycleType.RESULT_ANALYSIS_ONLY && 
                <PermissionControlledButton
                    testId={'submit-boundaries-button'}
                    userAccessLevels={this.props.userAccessLevels}
                    actionType={ActionType.WRITE}
                    pagePermissionsMap={this.props.pagePermissionsMap}
                    hideIfNotAuthorized={true}
                    buttonProps={{
                        loading: this.state.submitBoundariesButtonLoading,
                        disabled: this.state.submitBoundariesButtonDisabled,
                        onClick: this.buttonHandlers.submitBoundaries,
                        variant: 'primary'
                    }}
                >Finalize Region</PermissionControlledButton>
              }
          >
              {this.state.displayRegionResults ?
                  (<>
                      {this.state.regionSimulationBoundarySets.length !== 0 || this.state.manualOverrideBoundarySets.length !== 0 ?
                          (<Alert
                              header="Notes"
                              data-testid='region-results-table-alert'
                          >
                            Choose one of the below options as your treatment region. These options are generated by our
                            region simulation which evenly distributes them across the marketplace based on historical OPS. Normally
                            you can go with the first option unless you want to exclude specific regions from the treatment.
                            You can submit your own treatment regions from the dropdown below, but we highly recommend choosing
                            from one of the provided options. If you go with this option, we will provide the OPS distribution
                            analysis within 6 hours to validate your choice.
                          </Alert>) : (<></>)
                      }
                      <div style={{ margin: '10px 0' }}/>
                      {this.state.regionSimulationBoundarySets.length !== 0 ?
                          (<DisplayTable
                              data-testid='region-simulation-results'
                              variant='borderless'
                              items={this.state.regionSimulationBoundarySets}
                              tableLoading={this.state.isLoading}
                              selectionType={'single'}
                              onSelectionChange={this.onBoundarySelectionChange}
                              stickyHeader={false}
                              selectedItems={this.state.selectedBoundarySet ? [this.state.selectedBoundarySet] : []}
                              title={
                                  <Header variant="h2">{TableHeaders.REGION_SIMULATION_RESULT} <span
                                      className="awsui-util-header-counter">({this.state.regionSimulationBoundarySets.length})</span>
                                  </Header>}
                              columnDefinitions={getColumnDefinitions(this.getBoundarySetsTableColumnName(this.state.regionSimulationBoundarySets))}
                              columnOptions={getColumnOptions(this.getBoundarySetsTableColumnName(this.state.regionSimulationBoundarySets))}
                              pageSizeOptions={pageSizeOptions}
                          />) : (<></>)
                      }
                      <div style={{ margin: '10px 0' }}/>
                      {this.state.manualOverrideBoundarySets.length !== 0 ?
                          (<DisplayTable
                              data-testid='pea-region-results'
                              items={this.state.manualOverrideBoundarySets}
                              tableLoading={this.state.isLoading}
                              selectionType={'single'}
                              stickyHeader={false}
                              onSelectionChange={this.onBoundarySelectionChange}
                              selectedItems={this.state.selectedBoundarySet ? [this.state.selectedBoundarySet] : []}
                              title={
                                  <Header variant="h2">{TableHeaders.MANUALLY_UPLOADED_BOUNDARIES} <span
                                      className="awsui-util-header-counter">({this.state.manualOverrideBoundarySets.length})</span>
                                  </Header>}
                              columnDefinitions={getColumnDefinitions(this.getBoundarySetsTableColumnName(this.state.manualOverrideBoundarySets))}
                              columnOptions={getColumnOptions(this.getBoundarySetsTableColumnName(this.state.manualOverrideBoundarySets))}
                              pageSizeOptions={pageSizeOptions}
                          />) : (<></>)
                      }
                      <div style={{ margin: '10px 0' }}/>
                      <div>
                          <TreatmentRegionUploadTypeField
                              data-testid='treatment-region-upload-type-radio-group'
                              updateFormState={((_fieldId, _payloadValue, displayValue, _isValid) => {
                                  this.setState({ useTreatmentRegionFile: displayValue });
                                  this.updateFormState(RegionSelectionAttribute.TREATMENT_BOUNDARIES, [], '', true);
                              })}
                          />
                          {this.getSelectionRegionStep()}
                          <div style={{ margin: '10px 0' }}/>
                          <Button
                              data-testid={'submit-override-boundaries-button'}
                              loading={this.state.submitOverrideBoundariesButtonLoading}
                              disabled={this.state.submitOverrideBoundariesButtonDisabled}
                              onClick={this.buttonHandlers.submitOverrideBoundaries}
                          >Submit For Manual Override</Button>
                      </div>
                  </>) :
                  <>
                      {this.props.experiment.metadata.experimentType.payloadValue === LifecycleType.RESULT_ANALYSIS_ONLY
                          ? <div>
                              {treatmentBoundariesList}
                              {controlBoundariesList}
                          </div>
                          : <ColumnLayout columns={2} variant='text-grid'>
                              {treatmentBoundariesList}
                              {regionStatus}
                          </ColumnLayout>}
                      {(this.props.experiment.metadata.experimentType.payloadValue === LifecycleType.RESULT_ANALYSIS_ONLY
                            || this.props.experiment.metadata.regionCreationStatus.payloadValue === RablRegionCreationStatusType.LIVE)
                        && <iframe src={this.state.rablEmbedUrl}
                            title='Region map'
                            width='100%'
                            height='500'
                            frameBorder='0'
                            scrolling='no'
                            style={{ padding: '20px 0px 1px 0px' }}
                        />}
                  </>
              }
          </ExpandableSection>

          {modals}
      </>);
      return (
          <div style={{ paddingBottom: 20 }}>
              {content}
          </div>
      );
  }
}
