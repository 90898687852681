import React from 'react';
import * as LambdaModel from '@amzn/limestone-experiment-portal-types';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { MultiSelectField, MultiSelectFieldConfig } from '../fields/MultiSelectField';
import { AttributeLabels, DisplayMode, MetadataAttribute, Realm, CustomExperimentMetricDto } from '@amzn/limestone-experiment-portal-types';
import { Box, SelectProps } from '@amzn/awsui-components-react-v3';
import { LabelWithTooltip } from './LabelWithTooltip';
import { IconButton } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';

export class AdditionalCustomMetricsField extends MultiSelectField<ExperimentAttributeProps> {
    protected displayConfig: MultiSelectFieldConfig;
    private experimentServiceAPI: LemsApiHandler;
    private realm: Realm;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        this.displayConfig = {
            editable: true,
            touched: false,
            placeholder: 'Select from the drop-down',
            filteringType: 'auto',
            label: AttributeLabels.ADDITIONAL_CUSTOM_METRICS,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, MetadataAttribute.ADDITIONAL_CUSTOM_METRICS),
            options: [
                {
                    value: 'PRIME_SIGNUPS',
                    label: 'Prime Signups',
                    description: 'Collecting number of prime signups during the experiment',
                }
            ],
            selectedOptions: [],
        };

        this.realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(this.realm);

        this.state = {
            displayValue: '',
            displayMode: props.displayMode ? props.displayMode : DisplayMode.CREATE,
            validity: true,
            editInProgress: true,
        };
    }

    componentDidMount = async() => {
        this.experimentServiceAPI.getCustomMetricOptions()
            .then((options: LambdaModel.CustomMetricDisplayDto[]) => {
                this.displayConfig.options = options.map((option) => {
                    return {
                        value: option.metricType,
                        label: option.displayName,
                        description: option.description,
                    };
                });

                if (this.props.initialValue) {
                    this.setValueFromPayload(this.props.initialValue);
                    const disabledMetricTypes = this.props.displayMode !== DisplayMode.CREATE ? this.props.initialValue.map((metric: CustomExperimentMetricDto) => metric.metricType) : [];
                    (this.displayConfig.options as SelectProps.Option[]).forEach((option) => {
                        if (disabledMetricTypes.includes(option.value)) {
                            option.disabled = true;
                        }
                    });
                }

                this.forceUpdate();
            })
            .finally(() => this.setState({ editInProgress: false }));
    }

    renderViewMode = (): JSX.Element => (
        <div>
            <div style={{ display: 'flex' }}>
                <LabelWithTooltip label={<div data-testid={`display-wrapper-${this.displayConfig.label}`} style={{ display: 'table-cell' }}>
                    <Box variant="awsui-key-label"><Box variant="strong"><u>{this.displayConfig.label}</u></Box></Box>
                </div>} toolTipText={this.displayConfig.toolTipText}/>
                {this.displayConfig.editable && this.props.isAuthorizedToEdit ? (<IconButton style={{ height: 0 }} onClick={() => this.setState({ displayMode: DisplayMode.EDIT })}><EditIcon fontSize='small'/></IconButton>) : null}
            </div>
            <div>{this.state.displayValue}</div>
        </div>
    );

    getPayloadValue = (): LambdaModel.CustomExperimentMetricDto[] => this.getSelectedOptions().map((selectedOption) => {
        return {
            metricType: selectedOption.value!
        };
    });

    setValueFromPayload = async(customExperimentMetrics: LambdaModel.CustomExperimentMetricDto[]) => {
        const selectedIds = customExperimentMetrics.map((customExperimentMetric) => customExperimentMetric.metricType);
        await this.setValueByIds(selectedIds);
    }

    editSubmitButtonClicked = async(): Promise<void> => {
        this.setState({ editInProgress: true });

        const newValues = this.getSelectedOptions()
            .filter((option) => option.disabled !== true)
            .map((selectedOption) => {
                return {
                    metricType: selectedOption.value!,
                };
            });

        if (newValues.length > 0) {
            await this.experimentServiceAPI.addCustomMetricsToExperiment(this.props.experimentId!, newValues)
                .finally(() => {
                    this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW });
                });
        }
    }
}
