import React, { FunctionComponent } from 'react';
import { DateRangePicker } from '@amzn/awsui-components-react-v3';
import { NonCancelableEventHandler } from '@amzn/awsui-components-react-v3/polaris/internal/events';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3/polaris/date-range-picker/interfaces';

export interface DateRangeSelectorProps {
    /**
     * Value set to the date range picker.
     */
    timeValue: DateRangePickerProps.Value | null;
    /**
     * Specifies the placeholder text that is rendered when the value is empty.
     */
    placeholder?: string;
    /**
     * Event triggered when the component's value is changed.
     * The event `detail` contains the current value of the field.
     */
    onChangeEvent?: NonCancelableEventHandler<DateRangePickerProps.ChangeDetail>;
    /**
     * Customized relative time options.
     */
    relativeOptions?: DateRangePickerProps.RelativeOption[];

    testId?: string;
}

/**
 * Provide a common {@link DateRangePicker} react component.
 */
export const DateRangeSelector: FunctionComponent<DateRangeSelectorProps> = (props: DateRangeSelectorProps) => {

    return (
        <DateRangePicker
            data-test-id={props.testId}
            placeholder={props.placeholder ? props.placeholder : 'Filter by a date and time range'}
            onChange={props.onChangeEvent}
            value={props.timeValue}
            granularity={'month'}
            relativeOptions={props.relativeOptions ? props.relativeOptions : [
                {
                    key: 'previous-1-months',
                    amount: 1,
                    unit: 'month',
                    type: 'relative'
                },
                {
                    key: 'previous-3-months',
                    amount: 3,
                    unit: 'month',
                    type: 'relative'
                },
                {
                    key: 'previous-6-months',
                    amount: 6,
                    unit: 'month',
                    type: 'relative'
                },
            ]}
            i18nStrings={{
                todayAriaLabel: 'Today',
                nextMonthAriaLabel: 'Next month',
                previousMonthAriaLabel: 'Previous month',
                customRelativeRangeDurationLabel: 'Duration',
                customRelativeRangeDurationPlaceholder:
                'Enter duration',
                customRelativeRangeOptionLabel: 'Custom range',
                customRelativeRangeOptionDescription:
                'Set a custom range in the past',
                customRelativeRangeUnitLabel: 'Unit of time',
                formatRelativeRange: (e) => {
                    const t =
                1 === e.amount ? e.unit : `${e.unit}s`;
                    return `Last ${e.amount} ${t}`;
                },
                formatUnit: (e, t) => (1 === t ? e : `${e}s`),
                dateTimeConstraintText:
                'For date, use YYYY/MM/DD. For time, use 24 hr format.',
                relativeModeTitle: 'Relative range',
                absoluteModeTitle: 'Absolute range',
                relativeRangeSelectionHeading: 'Choose a range',
                startDateLabel: 'Start date',
                endDateLabel: 'End date',
                startTimeLabel: 'Start time',
                endTimeLabel: 'End time',
                clearButtonLabel: 'Clear and dismiss',
                cancelButtonLabel: 'Cancel',
                applyButtonLabel: 'Apply'
            }}
            expandToViewport
            isValidRange={(range) => {
                if (range!.type === 'absolute') {
                    range = range as DateRangePickerProps.AbsoluteValue;
                    const [startDateWithoutTime] = range.startDate.split('T');
                    const [endDateWithoutTime] = range.endDate.split('T');
                    if (!startDateWithoutTime || !endDateWithoutTime) {
                        return {
                            valid: false,
                            errorMessage:
                                'The selected date range is incomplete. Select a start and end date for the date range.'
                        };
                    }
                    if (new Date(range.startDate).getTime() - new Date(range.endDate).getTime() > 0) {
                        return {
                            valid: false,
                            errorMessage:
                                'The selected date range is invalid. The start date must be before the end date.'
                        };
                    }
                }
                return { valid: true };
            }}
        />
    );
};

/**
 * Convert a {@link DateRangePickerProps.Value} especially a {@link DateRangePickerProps.RelativeValue}
 * to a {@link DateRangePickerProps.AbsoluteValue} to easily filter date within a range.
 */
export function convertToAbsoluteValue(timeValue: DateRangePickerProps.Value): DateRangePickerProps.AbsoluteValue {
    if (timeValue.type === 'relative') {
        const endDateTime = new Date();
        let startDateTime;
        const relativeTimeValue = timeValue as DateRangePickerProps.RelativeValue;
        switch (relativeTimeValue.unit) {
            case 'second':
                startDateTime = new Date(endDateTime.getTime() - relativeTimeValue.amount * 1000);
                break;
            case 'minute':
                startDateTime = new Date(endDateTime.getTime() - relativeTimeValue.amount * 60 * 1000);
                break;
            case 'hour':
                startDateTime = new Date(endDateTime.getTime() - relativeTimeValue.amount * 60 * 60 * 1000);
                break;
            case 'day':
                startDateTime = new Date(endDateTime.getTime() - relativeTimeValue.amount * 24 * 60 * 60 * 1000);
                break;
            case 'month':
                startDateTime = new Date(new Date().setMonth(endDateTime.getMonth() - relativeTimeValue.amount));
                break;
            case 'year':
                startDateTime = new Date(new Date().setFullYear(endDateTime.getFullYear() - relativeTimeValue.amount));
                break;
            default:
                throw new Error(`Invalid time unit: ${relativeTimeValue.unit}`);
        }

        return {
            startDate: startDateTime.toString(),
            endDate: endDateTime.toString(),
            type: 'absolute'
        };
    } else {
        return timeValue;
    }
}
