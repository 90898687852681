import React, { FunctionComponent } from 'react';
import { Container, Header, KeyValuePairs, Box } from '@amzn/awsui-components-react-v3';
import { LimestoneExperiment, ExperimentStatusType } from '@amzn/limestone-experiment-portal-types';

export interface ExperimentSummaryTableProps {
    experiments: LimestoneExperiment[];
    statusMap: Record<string, string[]>;
}

interface SummaryItem {
    label: string;
    value: React.ReactNode;
}

const EXCLUDED_PROGRESS_STATUSES = ['Completed'];

export const ExperimentSummaryTable: FunctionComponent<ExperimentSummaryTableProps> = (props: ExperimentSummaryTableProps) => {
    const filteredStatusMap = Object.fromEntries(
        Object.entries(props.statusMap)
            .filter(([status]) => !EXCLUDED_PROGRESS_STATUSES.includes(status))
    );
    const aggregatedSummaryData = Object.entries(filteredStatusMap).reduce((acc, [progressStatus, experimentStatuses]) => {
        const count = props.experiments.filter((experiment) => 
            experimentStatuses.includes(experiment.currentStatus.currentStatus.payloadValue)
        ).length;
        acc.set(progressStatus, count);
        return acc;
    }, new Map<string, number>());
    
    props.experiments.forEach((experiment) => {
        if (experiment.currentStatus.currentStatus.payloadValue === ExperimentStatusType.COMPLETE && experiment.launchDecision) {
            const currentCount = aggregatedSummaryData.get(experiment.launchDecision) ?? 0;
            aggregatedSummaryData.set(experiment.launchDecision, currentCount + 1);
        }
    });

    const items: SummaryItem[] = Array.from(aggregatedSummaryData.entries()).map(([key, value]) => ({
        label: key,
        value: (
            <Box variant="awsui-value-large">
                {value}
            </Box>
        )
    }));

    return (
        <div style={{ marginBottom: '10px' }}>
            <Container
                header={
                    <Header variant="h2" description={'Select date or status filter to view subset of experiments'}>
                        Overview
                    </Header>
                }
                fitHeight={true}
            >
                <KeyValuePairs
                    columns={5}
                    items={items}
                />
            </Container>
        </div>
    );
};