import { AttributeLabels,
    CustomEvaluationRequest,
    DisplayAttribute,
    DownstreamMetricRequest,
    LimestoneExperiment,
    LifecycleType } from '@amzn/limestone-experiment-portal-types';

/**
 * Creates an default limestone experiment entity.
 * @returns empty limestone experiment entity.
 */
export const createEmptyLimestoneExperiment = (): LimestoneExperiment => {
    return {
        experimentId: '',
        metadata: {
            marketplace: new DisplayAttribute(AttributeLabels.MARKETPLACE),
            title: new DisplayAttribute(AttributeLabels.TITLE),
            description: new DisplayAttribute(AttributeLabels.DESCRIPTION),
            productFamily: new DisplayAttribute(AttributeLabels.PRODUCT_FAMILY),
            primaryBusinessGroup: new DisplayAttribute(AttributeLabels.PRIMARY_BUSINESS_GROUP, true),
            secondaryBusinessGroups: new DisplayAttribute(AttributeLabels.SECONDARY_BUSINESS_GROUPS, true, []),
            primaryOwner: new DisplayAttribute(AttributeLabels.PRIMARY_OWNER),
            secondaryOwners: new DisplayAttribute(AttributeLabels.SECONDARY_OWNERS, true, []),
            observers: new DisplayAttribute(AttributeLabels.OBSERVERS, true, []),
            tags: new DisplayAttribute(AttributeLabels.TAGS, true, []),
            rablRegionId: new DisplayAttribute(AttributeLabels.RABL_REGION_ID, true),
            discriminator: new DisplayAttribute(AttributeLabels.DISCRIMINATOR, true),
            startDate: new DisplayAttribute(AttributeLabels.START_DATE, false, ''),
            endDate: new DisplayAttribute(AttributeLabels.END_DATE, false, ''),
            regionCreationStatus: new DisplayAttribute(AttributeLabels.REGION_CREATION_STATUS, true),
            businessApprover: new DisplayAttribute(AttributeLabels.BUSINESS_APPROVER),
            experimentType: new DisplayAttribute(AttributeLabels.EXPERIMENT_TYPE, true, LifecycleType.BLOCK_LIST, LifecycleType.BLOCK_LIST), // default experiment type is BLOCK_LIST
            linkedMultiTreatmentExperimentIds: new DisplayAttribute(AttributeLabels.LINKED_MULTI_TREATMENT_EXPERIMENT_IDS, true, []),
            bundleEnabled: new DisplayAttribute(AttributeLabels.BUNDLE_ENABLED, true, true, ''),
            customMetrics: new DisplayAttribute(AttributeLabels.ADDITIONAL_CUSTOM_METRICS, true, [], ''),
            regionDefinitionType: new DisplayAttribute(AttributeLabels.REGION_DEFINITION_TYPE, true, ''),
            dealsEnabled: new DisplayAttribute(AttributeLabels.DEALS_ENABLED, true, true),
            treatmentType: new DisplayAttribute(AttributeLabels.TREATMENT_TYPE, true, ''),
            proportionsOfMarketplace: new DisplayAttribute(AttributeLabels.PROPORTIONS_OF_MARKETPLACE, false, []),
            oamHoldout: new DisplayAttribute(AttributeLabels.OAM_HOLDOUT, true, false, ''),
        },
        productSelection: {
            offersFile: new DisplayAttribute(AttributeLabels.OFFERS_FILE)
        },
        treatmentRegionSelection: {
            boundaries: new DisplayAttribute(AttributeLabels.TREATMENT_BOUNDARIES)
        },
        controlRegionSelection: {
            boundaries: new DisplayAttribute(AttributeLabels.CONTROL_BOUNDARIES)
        },
        currentStatus: {
            currentStatus: new DisplayAttribute(AttributeLabels.CURRENT_STATUS, true),
            lastRefreshed: new DisplayAttribute(AttributeLabels.LAST_REFRESHED, true)
        }
    };
};

/**
 * Creates a default custom evaluation request.
 * @returns empty custom evaluation request.
 */
export const createEmptyCustomEvaluationRequest = (): CustomEvaluationRequest => {
    return {
        name: '',
        selection: null,
        metricTypes: [],
        editing: true,
    };
};

/**
 * Creates a default downstream metric request.
 * @returns empty downstream metric request.
 */
export const createEmptyDownstreamMetricsRequest = (): DownstreamMetricRequest => {
    return {
        useDefaultCustomerTriggers: true,
        startDate: '',
        endDate: '',
        customerTriggersFile: null,
        useDefaultScienceModel: true,
    };
};
