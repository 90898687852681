import { Box, ExpandableSection, Header, Table } from '@amzn/awsui-components-react-v3';
import React from 'react';
import { PowerAnalysisResult } from '@amzn/limestone-experiment-portal-types';
import Papa from 'papaparse';

interface PowerAnalysisSectionProps {
    powerAnalysisResults: PowerAnalysisResult[];

}

const getTableItems = (powerAnalysisResult: string) => {
    const rows: string[][] = Papa.parse<string[]>(powerAnalysisResult, { header: false }).data;
    const columnNames = rows[0];
    const dataRows = rows.slice(1);

    return dataRows.map((row) => {
        const item: {[key: string]: string} = {};
        for (let i = 0; i < columnNames.length; i++) {
            item[columnNames[i]] = row[i];
        }
        return item;
    });
};

const getColumnDefinitions = (powerAnalysisResult: string) => {
    const rows: string[][] = Papa.parse<string[]>(powerAnalysisResult, { header: false }).data;
    const columnNames = rows[0];

    return columnNames.map((columnName, i) => {
        return {
            id: columnName,
            header: columnName,
            cell: (item: { [key: string]: string }) => <div>{item[columnName]}</div>,
            minWidth: // Set the minimum width based on the longest non-header element in each column
                Math.max(
                    Math.max(...(rows.slice(1).map((row) => row[i]).map((s) => s.length))) * 15,
                    100
                )
        };
    });
};

const PowerAnalysisSection = ({ powerAnalysisResults }: PowerAnalysisSectionProps) => {
    return <div style={{ paddingBottom: 20 }}>
        <ExpandableSection
            headerText={'Power Analysis'} variant='container'>
            {powerAnalysisResults.map((powerAnalysisResult) => {
                powerAnalysisResult.result = powerAnalysisResult.result.trim();
                return (
                    <Box padding={'s'} key={powerAnalysisResult.configuration}>
                        <Table
                            items={getTableItems(powerAnalysisResult.result)}
                            columnDefinitions={getColumnDefinitions(powerAnalysisResult.result)}
                            header={<Header>{powerAnalysisResult.configuration}</Header>}
                            stickyColumns={{ first: 2 }}
                            variant='borderless'
                            stripedRows
                            wrapLines
                        />
                    </Box>
                );
            })}
        </ExpandableSection>
    </div>;
};

export default PowerAnalysisSection;
