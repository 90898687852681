import { TextAreaField, TextAreaFieldConfig } from '../fields/TextAreaField';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { ExperimentAttributeProps } from '../ExperimentAttribute';
import { AttributeLabels, DisplayMode, Realm } from '@amzn/limestone-experiment-portal-types';

/**
 * Recommendation for an experiment given by business team.
 */
export class BusinessRecommendation extends TextAreaField {
    protected displayConfig: TextAreaFieldConfig;
    private experimentServiceAPI: LemsApiHandler;

    constructor(props: ExperimentAttributeProps) {
        super(props);

        const { initialValue, displayMode } = props;
        const realm = props.realm ? props.realm : Realm.NA;
        this.experimentServiceAPI = new ApiHandler(realm);

        this.validationRules = { required: true, maxLength: 2048 };
        const { isValid, errorText } = this.validate(initialValue, this.validationRules);

        this.displayConfig = {
            label: AttributeLabels.BUSINESS_RECOMMENDATION,
            editable: true,
            rows: 3,
            placeholder: '2048 characters limit.',
            touched: false,
            hintText: '',
            errorText,
            onChange: (event) => this.onChangeEvent(event as CustomEvent, ''),
            value: props.initialValue
        };

        this.state = {
            displayValue: initialValue,
            displayMode: displayMode ? displayMode : DisplayMode.CREATE,
            validity: isValid,
            editInProgress: false
        };
    }

    componentDidMount() {
        if (this.props.initialValue) {
            this.setValueFromPayload(this.props.initialValue ? this.props.initialValue : '');
        }

        this.forceUpdate();
    }

    editSubmitButtonClicked = async(): Promise<boolean> => {
        this.setState({ editInProgress: true });
        const response = await this.experimentServiceAPI.updateBusinessRecommendation(this.props.experimentId!, this.getPayloadValue())
            .finally(() => { this.setState({ editInProgress: false, displayMode: DisplayMode.VIEW }); });

        return response;
    }
}
