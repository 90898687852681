import { filterExperimentsByDate } from '../../interfaces/LimestoneExperiment';
import { Header } from '@amzn/awsui-components-react-v3';
import React, { Component } from 'react';
import ApiHandler from '../../api/experiment-service/handler/lems-api-handler-impl';
import { DisplayTable } from '../../common/DisplayTable';
import { LemsApiHandler } from '../../api/experiment-service/handler/lems-api-handler';
import { PageProps, isTestExperiment, isCancelledExperiment, LimestoneExperiment } from '@amzn/limestone-experiment-portal-types';
import {
    allExperimentColumnOptions,
    getAllExperimentsTableColumnDefinition,
    pageSizeOptions
} from '../../constants/table/experiment-table/experiment-table-definition';
import { ExperimentStatusType } from '../../enums/ExperimentStatus';
import { handleErrorResponse } from '../../utils/error-handler-utils';
import * as NOTIFICATION_MESSAGES from '@amzn/limestone-experiment-portal-types';
import { Realm } from '@amzn/limestone-experiment-portal-types';
import { TableHeaders } from '@amzn/limestone-experiment-portal-types';
import { mapExperimentsToExperimentsTableItems } from '../../utils/experiments-table-utils';
import { PermissionControlledView } from '../../permissions/PermissionControlledView';
import { DateRangeSelectorProps } from '../../common/DateRangeSelector';
import { DateRangePickerProps } from '@amzn/awsui-components-react-v3/polaris/date-range-picker/interfaces';
import {
    EXPERIMENT_FILTERING_PROPERTIES
} from '../../constants/table/experiment-table/experiment-table-filter-properties';
import { filterItemsByAttribute } from '../../common/AttributeSelectFilter';
import { SelectProps } from '@amzn/awsui-components-react-v3/polaris/select/interfaces';
import { ExperimentSummaryTable } from '../../common/ExperimentSummaryTable';

export interface AllExperimentsPageState {
    experiments: LimestoneExperiment[];
    tableLoading: boolean;
    /**
     * Selected experiment start date range to filter experiments.
     */
    selectedStartDateRangeValue: DateRangePickerProps.Value | null;
    /**
     * Selected experiment status to filter experiment.
     */
    selectedExperimentStatusOption: SelectProps.Option | null;
    progressStatusMap: Record<string, string[]>;
}

class AllExperimentsPage extends Component<PageProps, AllExperimentsPageState> {
    public experimentServiceAPIs: Record<string, LemsApiHandler>;

    constructor(props: PageProps) {
        super(props);
        this.state = {
            tableLoading: true,
            experiments: [],
            selectedStartDateRangeValue: null,
            selectedExperimentStatusOption: null,
            progressStatusMap: {}
        };


        this.experimentServiceAPIs = {
            NA: new ApiHandler(Realm.NA),
            EU: new ApiHandler(Realm.EU),
            FE: new ApiHandler(Realm.FE)
        };
    };

    componentDidMount = async() => {
        await this.fetchExperiments();
    }

    fetchExperiments = async() => {
        this.setState({ tableLoading: true });

        try {
            const [naExperiments, euExperiments, feExperiments, progressStatusMap] = await Promise.all([
                this.experimentServiceAPIs.NA.getExperimentsInGivenStatuses(Object.values(ExperimentStatusType)),
                this.experimentServiceAPIs.EU.getExperimentsInGivenStatuses(Object.values(ExperimentStatusType)),
                this.experimentServiceAPIs.FE.getExperimentsInGivenStatuses(Object.values(ExperimentStatusType)),
                this.experimentServiceAPIs.NA.getExperimentSummaryTableData()
            ]);
    
            this.setState({ 
                experiments: [...naExperiments, ...euExperiments, ...feExperiments],
                progressStatusMap
            });
        } catch (error) {
            handleErrorResponse(error, this.props.setNotification!, NOTIFICATION_MESSAGES.getExperimentsInGivenStatuses.FAIL!);
        } finally {
            this.setState({ tableLoading: false });
        }
    }

    /**
     * Event that handles submit edit in the table.
     *
     * @param currentExperiment the experiment that is edited
     * @param column column edited
     * @param value new value of the cell
     */
    tableEditOnSubmitEvent = async (currentExperiment: LimestoneExperiment, _column: any, _value: any) => {
        this.setState({
            experiments: this.state.experiments.map((experiment) => experiment.experimentId === currentExperiment.experimentId ? currentExperiment : experiment)
        });
    }

    render() {
        const testExperiments = this.state.experiments.filter((experiment) => isTestExperiment(experiment));
        const cancelledExperiments = this.state.experiments.filter((experiment) => isCancelledExperiment(experiment));
        const experimentsToDisplay = this.state.experiments.filter((experiment) => (!isTestExperiment(experiment) && !isCancelledExperiment(experiment)));

        const dateRangeSelectorProps: DateRangeSelectorProps = {
            onChangeEvent: ({ detail }) => this.setState({ selectedStartDateRangeValue: detail.value }),
            timeValue: this.state.selectedStartDateRangeValue,
            placeholder: 'Filter by experiment start date',
            testId: 'all-experiments-table-date-picker',
        };

        // Filter all the chosen attributes to get the experiments to display
        const filteredExperimentsToDisplay = filterItemsByAttribute(mapExperimentsToExperimentsTableItems(
            filterExperimentsByDate(
                experimentsToDisplay, this.state.selectedStartDateRangeValue
            )
        ),
        'currentStatusToDisplay', this.state.selectedExperimentStatusOption!);

        return (
            <PermissionControlledView
                userAccessLevels={this.props.userAccessLevels}
                pagePermissionsMap={this.props.permissionsMap}
            >
                <ExperimentSummaryTable experiments={filteredExperimentsToDisplay} statusMap={this.state.progressStatusMap}/>
                <DisplayTable
                    title={<Header variant="h2" counter={String(filteredExperimentsToDisplay.length)}>{TableHeaders.ALL_EXPERIMENTS}</Header>}
                    items={filteredExperimentsToDisplay}
                    tableLoading={this.state.tableLoading}
                    columnDefinitions={getAllExperimentsTableColumnDefinition(this.props.realm)}
                    columnOptions={allExperimentColumnOptions}
                    pageSizeOptions={pageSizeOptions}
                    preferencesEnabled={true}
                    initialSortingDescending={true}
                    initialSortingId={'startDate'}
                    dateRangeSelectorProps={dateRangeSelectorProps}
                    propertyFilterProperties={EXPERIMENT_FILTERING_PROPERTIES}
                    attributeSelectFilterProps={[
                        {
                            items: mapExperimentsToExperimentsTableItems(experimentsToDisplay),
                            attributeName: 'currentStatusToDisplay',
                            attributeLabel: 'experiment status',
                            selectedOption: this.state.selectedExperimentStatusOption!,
                            onChangeEvent: ({ detail }) => this.setState({ selectedExperimentStatusOption: detail.selectedOption }),
                        }
                    ]}
                    tableEditOnSubmitEvent={this.tableEditOnSubmitEvent}
                />
                {cancelledExperiments.length !== 0 && <div style={{ paddingTop: 20 }}>
                    <DisplayTable
                        title={<Header variant="h2" counter={String(cancelledExperiments.length)}>{TableHeaders.ALL_EXPERIMENTS_CANCELLED}</Header>}
                        items={mapExperimentsToExperimentsTableItems(filterExperimentsByDate(cancelledExperiments, this.state.selectedStartDateRangeValue))}
                        tableLoading={this.state.tableLoading}
                        columnDefinitions={getAllExperimentsTableColumnDefinition(this.props.realm)}
                        columnOptions={allExperimentColumnOptions}
                        pageSizeOptions={pageSizeOptions}
                        preferencesEnabled={true}
                        initialSortingDescending={true}
                        initialSortingId={'startDate'}
                        tableEditOnSubmitEvent={this.tableEditOnSubmitEvent}
                    /></div>}
                {testExperiments.length !== 0 && <div style={{ paddingTop: 20 }}>
                    <DisplayTable
                        title={<Header variant="h2" counter={String(testExperiments.length)}>{TableHeaders.ALL_EXPERIMENTS_TEST}</Header>}
                        items={mapExperimentsToExperimentsTableItems(filterExperimentsByDate(testExperiments, this.state.selectedStartDateRangeValue))}
                        tableLoading={this.state.tableLoading}
                        columnDefinitions={getAllExperimentsTableColumnDefinition(this.props.realm)}
                        columnOptions={allExperimentColumnOptions}
                        pageSizeOptions={pageSizeOptions}
                        preferencesEnabled={true}
                        initialSortingDescending={true}
                        initialSortingId={'startDate'}
                        tableEditOnSubmitEvent={this.tableEditOnSubmitEvent}
                    /></div>}
            </PermissionControlledView>
        );
    }
}

export default AllExperimentsPage;
