import React, { FunctionComponent, useState } from 'react';
import { DataTableGroup, DataTableGroupDefinition, exportDataTablesToCSV } from '../../common/DataTableGroup';
import { Box, SpaceBetween, FormField, Spinner, Select, SelectProps, Flashbar, NonCancelableCustomEvent, Button, Grid, Header, Container } from '@amzn/awsui-components-react-v3';
import { LimestoneExperiment, Realm } from '@amzn/limestone-experiment-portal-types';
import { downloadFile, FileFormat } from '../../utils/file-utils';
import _ from 'lodash';
import ApiHandler from '../../api/data-collection/handler/cdc-api-handler-impl';
import { ErrorBoundary } from '../../common/ErrorBoundary';
import { Accordion, AccordionDetails, AccordionSummary } from '@material-ui/core';
import { ExpandMore } from '@material-ui/icons';

export interface MetricsPageDisplayProps {
    id: string;
    experiment: LimestoneExperiment;
    tableGroups: DataTableGroupDefinition[];
    tableKey: string;
    dataNotReadyWarning?: string;
    addMetricElement?: JSX.Element;
    availableDays: string[];
    showSpinner: boolean;
    onDateChangeFunction: (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => any;
    enableExperimentSummary?: boolean;
    subheader?: JSX.Element;
}

export const MetricsPageDisplay: FunctionComponent<MetricsPageDisplayProps> = (props: MetricsPageDisplayProps) => {
    let dataCollectionAPI = new ApiHandler(Realm.NA);
    const [buttonLoadingState, updateButtonLoadingState] = useState(false);
    const [selectedDay, setSelectedDay] = useState('');
    const [experimentSummary, setExperimentSummary] = useState(<div></div>);

    const tables = props.tableGroups.length > 0 ? props.tableGroups.map((dataTableGroup: DataTableGroupDefinition) => (
        <div style={{ padding: '10px 0 10px 0' }} key={dataTableGroup.groupName ? dataTableGroup.groupName : props.tableKey}>
            <DataTableGroup
                dataTableGroupDefinition={dataTableGroup}
                expandableSection={true}
                firstColumnHeader={true}
            />
        </div>)) : null;

    const downloadMetrics = () => {
        updateButtonLoadingState(true);

        const fileRows = exportDataTablesToCSV(props.tableGroups);
        downloadFile(fileRows, `${props.experiment.experimentId}-${props.id}`, FileFormat.CSV);

        updateButtonLoadingState(false);
    };

    const onChange = (event: NonCancelableCustomEvent<SelectProps.ChangeDetail>) => {
        const selectedDay = event.detail.selectedOption.value!;
        setSelectedDay(selectedDay);
        setExperimentSummary(<div></div>);
        props.onDateChangeFunction(event);
    };

    const getExperimentSummary = async () => {
        setExperimentSummary(<Spinner data-testid={'experiment-summary-spinner'} size='normal' />);

        const summary = await dataCollectionAPI.getExperimentSummary(props.experiment.experimentId, selectedDay);

        if (summary === null) {
            setExperimentSummary(<Box>Experiment summary could not be loaded.</Box>);
        } else {
            setExperimentSummary(<p style={{ whiteSpace: 'pre-line' }}>{summary.Summary}</p>);
        }
    };

    let experimentSummarySection: JSX.Element | null = null;
    let headerContent: JSX.Element|null;
    if (props.showSpinner) {
        headerContent = (<Spinner data-testid={'metrics-loading-spinner'} size='large' />);
    } else if (props.availableDays.length === 0) {
        headerContent = props.dataNotReadyWarning ? (
            <Flashbar data-testid={`${props.id}-no-dates-flashbar`} items={[{
                header: props.dataNotReadyWarning,
                type: 'warning'
            }]} />
        ) : null;
    } else {
        if (props.enableExperimentSummary && tables) {

            experimentSummarySection = (
                <ErrorBoundary>
                    <div style={{ padding: '10px 0 10px 0' }}>
                        <Accordion>
                            <AccordionSummary expandIcon={<ExpandMore />} aria-controls="panel1a-content" id="panel1a-header">
                                <Header variant='h2'>Experiment Summary</Header>
                            </AccordionSummary>

                            <AccordionDetails>
                                <SpaceBetween size="s">
                                    {experimentSummary}
                                    <Grid gridDefinition={[{ colspan: 10 }, { colspan: 5 }]}>
                                        <Button variant="primary" onClick={getExperimentSummary}>
                                            Summarize Experiment Results
                                        </Button>
                                    </Grid>
                                </SpaceBetween>
                            </AccordionDetails>
                        </Accordion>
                    </div>
                </ErrorBoundary>
            );
        }
        headerContent = (
            <>
                <SpaceBetween size="xl">
                    <Grid gridDefinition={[{ colspan: 6 }, { colspan: 6 }]}>
                        <Box variant="h4" padding="n">Select a date from the drop-down</Box>
                        <div style={{ textAlign: 'right' }}>
                            {!_.isEmpty(props.tableGroups) &&
                                <Button
                                    data-testid={`${props.id}-download-button`}
                                    loading={buttonLoadingState}
                                    onClick={downloadMetrics}
                                    variant='primary'
                                    iconName='download'
                                >
                                    {`${props.tableKey} for ${selectedDay}`}
                                </Button>
                            }
                        </div>
                    </Grid>

                    {props.subheader && <Box>{props.subheader}</Box>}
                    <FormField stretch={false}>
                        <Select
                            data-testid={'date-change-dropdown'}
                            options={props.availableDays.map((day) => { return { value: day };})}
                            selectedOption={{ value: selectedDay }}
                            onChange={onChange}
                            key='dse-date-change-dropdown'
                        />
                    </FormField>
                </SpaceBetween>
            </>

        );
    }


    return (
        <Container>
            {props.addMetricElement}
            {headerContent}
            {experimentSummarySection}
            {tables}
        </Container>
    );
};
